//eslint-disable-next-line
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

import './styles/style.css'
import initAccordion from './features/accordion'
// Ensure GSAP plugins are ready
gsap.registerPlugin(SplitText, ScrollTrigger, ScrollToPlugin)

document.addEventListener('DOMContentLoaded', initAccordion)

document.addEventListener('DOMContentLoaded', function () {
  // Create a new timeline
  const fadeInTl = gsap.timeline()

  // Target all children of '.hero-header_image-group' for a staggered fade-in effect using autoAlpha
  fadeInTl
    .from('.hero-header_content > *', {
      autoAlpha: 0, // This takes care of both opacity and visibility
      duration: 0.9, // Duration of the fade-in effect for each element
      y: '2rem',
      stagger: 0.2, // Delay between the start of each element's animation
      ease: 'power2.out', // Easing function for the fade-in effect
    })
    .from(
      '.hero-header_image-group > *',
      {
        autoAlpha: 0, // This takes care of both opacity and visibility
        duration: 0.9, // Duration of the fade-in effect for each element
        y: '1rem',
        stagger: 0.2, // Delay between the start of each element's animation
        ease: 'power2.out', // Easing function for the fade-in effect
      },
      '-=.9'
    )
})

gsap.from('.logo_logo', {
  opacity: 0,
  y: '0.5rem',
  stagger: 0.1,
  duration: 0.5,
  scrollTrigger: {
    trigger: '.section_logo', // This targets the section to observe for scroll events
    start: 'top 80%', // When the top of the trigger hits the center of the viewport
  },
})

gsap.from('#speakers-wrapper', {
  opacity: 0,
  y: '0.5rem',
  duration: 0.5,
  scrollTrigger: {
    trigger: '.section_speakers', // This targets the section to observe for scroll events
    start: 'top bottom', // When the top of the trigger hits the center of the viewport
  },
})

// Create a timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.section_hero-header', // This targets the section to observe for scroll events
    start: 'top top', // When the top of the trigger hits the center of the viewport
    end: 'bottom top', // When the bottom of the trigger hits the top of the viewport
    scrub: 2, // Enables scrubbing so the animation progress is linked to scroll position
  },
})

// Target all children of '.hero-header_image-group' and create a staggered scale down animation
tl.to('.hero-header_image-group > *', {
  scale: 0.5,
  duration: 0.5, // Duration of each scale down animation
  ease: 'none', // Adjust the easing here if desired
})

// Remember to adjust the 'trigger', 'start', 'end', and animation parameters as needed to fit your specific needs.

document.addEventListener('DOMContentLoaded', function () {
  // Split the text into characters
  let split = new SplitText('#testimonial-quote', { type: 'words' })

  // Animate each character's opacity
  gsap.from(split.words, {
    opacity: 0.1,
    stagger: 1,
    ease: 'none',
    scrollTrigger: {
      trigger: '.section_testimonial-wrapper',
      start: 'top 30%', // Adjust start and end positions as needed
      end: 'bottom bottom',
      scrub: 1.5, // Smooth scrubbing effect tied to scroll position
      /*       pin: '.section_testimonial',
      anticipatePin: 1, */
    },
  })
})

//stats animation with satgger
gsap.from('.stats_text-wrapper', {
  opacity: 0,
  y: 20,
  duration: 0.7,
  stagger: 0.25, // 0.1 seconds between when each ".box" element starts animating
  scrollTrigger: {
    trigger: '.section_stats',
    start: '20% 75%',
  },
})
if (window.innerWidth > 767) {
  gsap.to('.banner11_marquee', {
    x: '-25vw',
    duration: 0.7,
    scrollTrigger: {
      trigger: '.section_tuscany',
      start: 'top 90%',
      scrub: 2,
    },
  })
} else {
  gsap.to('.banner11_marquee', {
    x: '-45%',
    duration: 0.7,
    ease: 'none',
    scrollTrigger: {
      trigger: '.section_tuscany',
      start: 'top 30%',
      end: 'bottom 30%',
      scrub: 1,
    },
  })
}
document.addEventListener('DOMContentLoaded', () => {
  // Initially set all modals to be hidden with gsap for a consistent starting point
  gsap.set('.request_modal', { display: 'none', autoAlpha: 0 })

  // Select buttons by the 'data-target' attribute
  const buttons = document.querySelectorAll('[data-target]')

  buttons.forEach((button) => {
    button.addEventListener('click', function () {
      const target = this.getAttribute('data-target')
      toggleModal(target)
    })
  })
})

let firstClick = true // Flag to track if any button has been clicked for the first time

function toggleModal(cardNumber) {
  const targetModal = document.getElementById('modal' + cardNumber)
  const isVisible = gsap.getProperty(targetModal, 'autoAlpha') > 0

  // If the target modal is already visible, just return
  if (isVisible) return

  // Ensure the modal is part of the document flow before animating
  targetModal.style.display = 'block'

  // Hide all other modals except the target
  document.querySelectorAll('.request_modal').forEach((modal) => {
    if (modal !== targetModal) {
      gsap.to(modal, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => (modal.style.display = 'none'),
      })
    }
  })

  if (firstClick) {
    // Only animate height on the first click
    gsap.fromTo(
      targetModal,
      { autoAlpha: 0, height: 0 },
      {
        duration: 0.5,
        autoAlpha: 1,
        height: 'auto',
        onComplete: () => {
          // Ensure this block only executes once
          // Use GSAP's ScrollTo plugin to scroll after a delay
          setTimeout(() => {
            gsap.to(window, {
              duration: 0.7,
              ease: 'power1.inOut',
              scrollTo: '.request_modal-wrapper',
            })
          }, 0)
        },
      }
    )
  } else {
    // Subsequent clicks only fade in the modal without height animation
    gsap.to(targetModal, { duration: 0.5, autoAlpha: 1 })
  }
}

// Stagger the cards
if (window.innerWidth > 992) {
  gsap.from('.pricing_plan', {
    y: '2rem',
    opacity: 0,
    duration: 0.8,
    stagger: 0.2,
    scrollTrigger: {
      trigger: '.section_pricing',
      start: '50% 100%',
      scrub: false,
    },
  })
}

// eslint-disable-next-line
const resizeObserver = new ResizeObserver((entries) => {
  // Optionally, you could check the entries for specific conditions
  ScrollTrigger.refresh()
})

// Observe specific elements
document.querySelectorAll('[resize="watched"]').forEach((elem) => {
  resizeObserver.observe(elem)
})

document.addEventListener('DOMContentLoaded', function () {
  const successDuration = 0.5
  document
    .querySelector('#wf-form-Pricing-Form-Classic')
    .addEventListener('submit', () => {
      gsap.to(window, {
        duration: successDuration,
        ease: 'power1.inOut',
        scrollTo: '#submission1',
      })
      setTimeout(() => {
        document.querySelector('#trigger1').click()
      }, 800)
    })

  document
    .querySelector('#wf-form-Pricing-Form-Premium')
    .addEventListener('submit', () => {
      gsap.to(window, {
        duration: successDuration,
        ease: 'power1.inOut',
        scrollTo: '#submission3',
      })
      setTimeout(() => {
        document.querySelector('#trigger3').click()
      }, 800)
    })

  /*   document
    .querySelector('#wf-form-Pricing-Form-Hero')
    .addEventListener('submit', () => {
      gsap.to(window, {
        duration: successDuration,
        ease: 'power1.inOut',
        scrollTo: '#submission3',
      })
      setTimeout(() => {
        document.querySelector('#trigger3').click()
      }, 800)
    }) */
})
