import { gsap } from 'gsap'

// Select all accordion questions
const questions = document.querySelectorAll('.schedule_question')
const ease = 'power2.out'
function openAccordion(answer) {
  if (window.innerWidth > 992) {
    gsap.to(answer, {
      height: 'auto',
      duration: 0.5,
      ease: ease,
    })
  } else {
    gsap.to(answer, {
      height: 'auto',
      duration: 0.5,
      ease: ease,
      onComplete: () => {
        // Ensure this block only executes once
        // Use GSAP's ScrollTo plugin to scroll after a delay
        setTimeout(() => {
          gsap.to(window, {
            duration: 1,
            ease: 'power1.inOut',
            scrollTo: { y: answer, offsetY: 120 },
          })
        }, 0)
      },
    })
  }
}

function closeAccordion(answer) {
  gsap.to(answer, {
    height: 0,
    duration: 0.5,
    ease: ease,
  })
}

// Function to bring the image to the front and fade in
function showImage(imageId) {
  gsap.to(imageId, { zIndex: 100, opacity: 1, duration: 0.5 })
}

// Function to send the image to the back and fade out
function hideImage(imageId) {
  gsap.to(imageId, { zIndex: 1, opacity: 0, duration: 0.5 })
}

function initAccordion() {
  let lastOpenedImageId = '#image1' // Initially, image1 is in front and visible
  showImage(lastOpenedImageId) // No need to fade in since it's already visible at start

  questions.forEach((question, index) => {
    // Close all accordions initially
    closeAccordion(question.nextElementSibling)

    question.addEventListener('click', () => {
      const answer = question.nextElementSibling
      const currentImageId = `#image${index + 1}`
      const iconWrapper = question.querySelector('.schedule_icon-wrapper')

      // Determine if the clicked accordion is currently open
      const isCurrentlyOpen = answer.style.height !== '0px'

      // Find any open accordion that is not the current one and close it
      const openAnswers = document.querySelectorAll(
        '.schedule_answer[style*="height: auto"]'
      )
      openAnswers.forEach((openAnswer) => {
        if (openAnswer !== answer) {
          closeAccordion(openAnswer)
          const openAnswerIcon =
            openAnswer.previousElementSibling.querySelector(
              '.schedule_icon-wrapper'
            )
          gsap.to(openAnswerIcon, { rotation: 0, duration: 0.5, ease: ease })
        }
      })

      // Toggle current accordion and chevron rotation
      if (isCurrentlyOpen) {
        closeAccordion(answer)
        gsap.to(iconWrapper, { rotation: 0, duration: 0.5, ease: ease })
        // If closing the currently open accordion, consider whether to hide its image
      } else {
        openAccordion(answer)
        gsap.to(iconWrapper, { rotation: 180, duration: 0.5, ease: ease })
        if (lastOpenedImageId !== currentImageId) {
          // Only fade out the last image and fade in the new one if they are different
          hideImage(lastOpenedImageId)
          showImage(currentImageId)
          lastOpenedImageId = currentImageId // Update the last opened image ID
        }
        // If the image associated with the currently opened accordion is already visible, do not re-animate it
      }
    })
  })
}

export default initAccordion
